import React from 'react';
import './App.css';
import Survey from 'components/Survey';

import 'normalize.css';
import 'typeface-roboto';

function App() {
  return (
    <div className="App">
      <Survey />
    </div>
  );
}

export default App;
