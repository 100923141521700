import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import firebase from 'modules/firebase';

import { v4 as uuidv4 } from 'uuid';

const submitSurvey = async (data) => {
  const db = firebase.firestore();

  const uuid = uuidv4();

  return db.collection('surveys').doc(uuid).set(data);
} 

const Survey = () => {
  const [success, setSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      stars: '5',
      activities: {
        kids_classes: false,
        preschool_center: false,
        kindergarten: false,
        babysitter: false,
        reading: false,
        blogs: false,
        internet: false,
        apps: false
      },
    },
  });

  const onSubmit = (data) => {
    setIsSending(true);
    submitSurvey(data).then(() => {
      setSuccess(true);
    });
  };

  if (success) return <Success />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>Оцените качество</legend>
          <label>
            <input ref={register} type='radio' name='stars' value={1} /> 1
          </label>
          <label>
            <input ref={register} type='radio' name='stars' value={2} /> 2
          </label>
          <label>
            <input ref={register} type='radio' name='stars' value={3} /> 3
          </label>
          <label>
            <input ref={register} type='radio' name='stars' value={4} /> 4
          </label>
          <label>
            <input ref={register} type='radio' name='stars' value={5} /> 5
          </label>
        </fieldset>

        <fieldset>
          <legend>Как вы ещё занимаетесь с ребёнком?</legend>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[kids_classes]'
              value={true}
            />{' '}
            Ходим в кружок
          </label>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[preschool_center]'
              value={true}
            />{' '}
            Посещаем образовательный центр
          </label>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[kindergarten]'
              value={true}
            />{' '}
            С ребёнком занимаются в детском саду
          </label>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[babysitter]'
              value={true}
            />{' '}
            Занимаемся с няней
          </label>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[reading]'
              value={true}
            />{' '}
            Читаю книги о развитии детей
          </label>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[blogs]'
              value={true}
            />{' '}
            Слежу за тематическими блоггерами
          </label>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[internet]'
              value={true}
            />{' '}
            Ищу информацию в интернете
          </label>
          <label>
            <input
              ref={register}
              type='checkbox'
              name='activities[apps]'
              value={true}
            />{' '}
            Используем развивающие игры или приложения на планшете/компьютере
          </label>
        </fieldset>

        <fieldset>
          <legend>Кто ещё в семье принимает участие в развитии ребёнка?</legend>
          <label>
            <input
              ref={register}
              type='radio'
              defaultChecked
              name='participating'
              value={false}
            />{' '}
            В основном занимаюсь только я
          </label>
          <label>
            <input
              ref={register}
              type='radio'
              name='participating'
              value={true}
            />{' '}
            Остальные родственники тоже принимают участие
          </label>
        </fieldset>

        <label htmlFor='note'>
          Что мы могли бы добавить или улучшить в нашей подписке?
        </label>
        <textarea
          ref={register}
          id='note'
          name='note'
          placeholder='Что можно добавить или улучшить в подписке?'
        />

        <button disabled={isSending} type='submit'>{!isSending ? 'Отправить' : 'Отправляем...'}</button>
      </form>
    </div>
  );
};

export default Survey;

const Success = () => (
  <div>Спасибо за участие в нашем опросе! Мы постараемся учесть ваши идеи и замечания!</div>
);