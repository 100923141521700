import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyBDAJDmBXqJvQBGjHK3bP5qqpMg7fScklw',
  authDomain: 'iq-project-social.firebaseapp.com',
  databaseURL: 'https://iq-project-social.firebaseio.com',
  projectId: 'iq-project-social',
  storageBucket: 'iq-project-social.appspot.com',
  messagingSenderId: '133275268856',
  appId: '1:133275268856:web:a3eeee00999b418665ed0d',
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
